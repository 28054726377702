import TestDemo from "../components/TestDemo"; // CTA section for generating link in bio
import AccordionSection from "../components/core/AccordionSection";
import ComparisonSection from "../components/ComparisonSection";
import OnboardingSection from "../components/OnboardingSection";
import SubscriptionPlans from "../components/SubscriptionPlans";
import HeroSection from "../components/HeroSection";
import ConversionCalculator from "../components/ConversionCalculator";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Landing = () => {
  return (
    <div className="bg-[#FAF9F6] min-h-screen font-sans overflow-x-hidden">
      {/* Header */}
      <Header />

      {/* Hero Section */}
      <section className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-b from-[#FAF9F6] to-[#F3E8FF]">
        <HeroSection />
      </section>

      {/* Conversion Calculator Section */}
      <section className="w-full max-w-full overflow-x-hidden py-12 flex items-center justify-center bg-gradient-to-b from-[#F3E8FF] to-[#F3E8FF]">
        <ConversionCalculator />
      </section>

      {/* Onboarding Section */}
      <section className="w-full max-w-full overflow-x-hidden min-h-screen flex items-center justify-center bg-gradient-to-b from-[#F3E8FF] to-[#D8C6F7]">
        <OnboardingSection />
      </section>

      {/* CTA Section */}
      <section className="w-full max-w-full overflow-x-hidden min-h-screen flex items-center justify-center bg-gradient-to-b from-[#D8C6F7] to-[#C4A8EF]">
        <TestDemo />
      </section>

      {/* Comparison Section */}
      <section className="w-full max-w-full overflow-x-hidden min-h-screen flex items-center justify-center bg-gradient-to-b from-[#C4A8EF] to-[#A98EE2]">
        <ComparisonSection />
      </section>

      {/* Subscription Plans */}
      <section className="w-full max-w-full overflow-x-hidden min-h-screen flex items-center justify-center bg-gradient-to-b from-[#A98EE2] to-[#8E73D4]">
        <SubscriptionPlans />
      </section>

      {/* Features Section */}
      <section className="w-full max-w-full min-h-screen flex items-center justify-center bg-gradient-to-b from-[#8E73D4] to-[#6F59B9]">
        <AccordionSection />
      </section>

      {/* Footer */}
      <section className="w-full max-w-full min-h-screen flex items-center justify-center bg-gradient-to-b from-[#6F59B9] to-[#4A3C85]">
        <Footer />
      </section>
    </div>
  );
};

export default Landing;
