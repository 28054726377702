import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

const ComparisonCard = ({ title, description, items, isPositive }) => {
  return (
    <div className="bg-white rounded-lg p-6 space-y-6 shadow-md">
      <div className="flex items-center space-x-1">
        <h2 className="text-md font-semibold text-black">{title}</h2>
        <p
          className={`text-md font-bold ${
            isPositive ? "text-green-400" : "text-red-400"
          }`}
        >
          {description}
        </p>
      </div>

      <ul className="space-y-4 text-gray-500">
        {items.map((item, index) => (
          <li key={index} className="flex items-start">
            <FontAwesomeIcon
              icon={isPositive ? faCheck : faTimes}
              className={`h-6 w-6 mr-2 flex-shrink-0 ${
                isPositive ? "text-green-400" : "text-red-400"
              }`}
            />
            <span>{item}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ComparisonCard;
