import React from 'react';

const BodyTeemuCoaching = () => {
  return (
    <div className="bg-gray-900 text-gray-200 min-h-screen">
      {/* Hero Section */}
      <section className="relative bg-gray-800 text-white py-20">
        <div className="container mx-auto px-6 text-center">
          <h1 className="text-5xl font-extrabold leading-tight">
            BodyTeemu Coaching
          </h1>
          <p className="mt-4 text-xl">
            Fysiikan ja mielen valmennusta yksilöllisesti
          </p>
          <button className="mt-6 bg-yellow-500 hover:bg-yellow-600 text-gray-900 py-3 px-6 rounded-lg font-semibold shadow-md transition">
            Ilmainen alkukartoitus
          </button>
        </div>
        <div className="absolute inset-0 bg-gradient-to-b from-gray-800 to-gray-900 opacity-60 pointer-events-none"></div>
      </section>

      {/* About Section */}
      <section className="py-20">
        <div className="container mx-auto px-6 text-center">
          <h2 className="text-4xl font-bold mb-6">Kokonaisvaltainen Hyvinvointi</h2>
          <p className="text-lg leading-relaxed max-w-3xl mx-auto">
            Haluan auttaa sinua saavuttamaan kokonaisvaltaisen hyvinvoinnin – sekä
            keholle että mielelle. Tarjoan sekä personal training -palveluita fyysisten
            tavoitteidesi tukemiseksi että life coaching -valmennusta mielen tasapainon
            ja tavoitteiden selkeyttämiseksi. Näitä palveluita voi hyödyntää erikseen tai
            yhdistettynä täydellisen tasapainon saavuttamiseksi.
          </p>
          <p className="mt-4 text-lg font-semibold text-yellow-500">
            Valmennus 49€/h tai sopimuksen mukaan.
          </p>
        </div>
      </section>

      {/* Services Section */}
      <section className="py-20 bg-gray-800">
        <div className="container mx-auto px-6">
          <h2 className="text-4xl font-bold text-center mb-12 text-yellow-500">
            Palvelut
          </h2>
          <div className="grid md:grid-cols-2 gap-12">
            {/* Personal Training */}
            <div className="bg-gray-900 p-8 rounded-lg shadow-lg hover:shadow-2xl transition-shadow">
              <h3 className="text-3xl font-bold text-white mb-4">Personal Training</h3>
              <ul className="space-y-2 text-gray-400">
                <li>Räätälöidyt treeni- ja ravinto-ohjelmat</li>
                <li>Kuntosaliohjaus</li>
                <li>Online-valmennus</li>
              </ul>
            </div>

            {/* Life Coaching */}
            <div className="bg-gray-900 p-8 rounded-lg shadow-lg hover:shadow-2xl transition-shadow">
              <h3 className="text-3xl font-bold text-white mb-4">Life Coaching</h3>
              <ul className="space-y-2 text-gray-400">
                <li>Selkeät tavoitteet ja suunnitelmat</li>
                <li>Stressinhallinnan työkalut</li>
                <li>Etä- ja lähitapaamiset tarpeidesi mukaan</li>
                <li>Parempi jaksaminen arjessa</li>
                <li>Uusia näkökulmia elämään</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="py-20 bg-gray-900">
        <div className="container mx-auto px-6">
          <h2 className="text-4xl font-bold text-center text-yellow-500 mb-12">
            Asiakkaiden Kokemuksia
          </h2>
          <div className="grid md:grid-cols-2 gap-12">
            <div className="bg-gray-800 p-8 rounded-lg shadow-lg">
              <p className="text-gray-300">
                "Teemu auttoi minua löytämään selkeyttä tavoitteisiini ja
                saavuttamaan paremman tasapainon elämässäni."
              </p>
              <p className="mt-4 text-yellow-500 font-bold">– Asiakas A</p>
            </div>
            <div className="bg-gray-800 p-8 rounded-lg shadow-lg">
              <p className="text-gray-300">
                "Treeneissä on aina hyvä meininki, ja tulokset puhuvat puolestaan!
                Suosittelen lämpimästi."
              </p>
              <p className="mt-4 text-yellow-500 font-bold">– Asiakas B</p>
            </div>
          </div>
        </div>
      </section>

      {/* Call-to-Action Section */}
      <section className="py-20 bg-gradient-to-b from-gray-800 to-gray-900 text-center">
        <div className="container mx-auto px-6">
          <h2 className="text-4xl font-bold mb-6 text-white">
            Ota Ensimmäinen Askelesi!
          </h2>
          <p className="text-lg mb-8 text-gray-300">
            Varaa ilmainen alkukartoitus ja aloita matkasi kohti parempaa
            hyvinvointia.
          </p>
          <button className="bg-yellow-500 hover:bg-yellow-600 text-gray-900 py-3 px-6 rounded-lg font-semibold shadow-md transition">
            Ota Yhteyttä
          </button>
        </div>
      </section>
    </div>
  );
};

export default BodyTeemuCoaching;
