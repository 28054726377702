import React from "react";

const Footer = () => {
  return (
    <footer className="text-gray-200">
      <div className="container mx-auto px-4">
        {/* Contact Section */}
        <div className="text-center mb-16">
          <h3 className="text-lg font-semibold text-white mb-4">Contact</h3>
          <p className="text-sm mb-4 text-gray-50">Max Lassila</p>
          <p className="text-sm mb-2">
            <a
              href="tel:+358458778993"
              className="underline text-gray-100 hover:text-gray-50 transition duration-200 mb-2"
            >
              +358 458778993
            </a>
          </p>
          <p className="text-sm">
            <a
              href="mailto:max.lassila@icloud.com"
              className="underline text-gray-100 hover:text-gray-50 transition duration-200"
            >
              max.lassila@icloud.com
            </a>
          </p>
        </div>

        {/* Footer Content */}
        <div className="flex flex-col items-center justify-between">
          {/* Main Footer Content */}
          <div className="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-3 gap-8 w-full md:max-w-5xl text-center md:text-left">
            {/* Company Section */}
            <div>
              <h3 className="text-lg font-semibold text-white mb-3">Company</h3>
              <ul className="space-y-1 text-sm">
                <li>
                  <a href="#" className="hover:text-white transition-colors">
                    About Us
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:text-white transition-colors">
                    Careers
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:text-white transition-colors">
                    Press
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:text-white transition-colors">
                    Blog
                  </a>
                </li>
              </ul>
            </div>

            {/* Resources Section */}
            <div>
              <h3 className="text-lg font-semibold text-white mb-3">
                Resources
              </h3>
              <ul className="space-y-1 text-sm">
                <li>
                  <a href="#" className="hover:text-white transition-colors">
                    Documentation
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:text-white transition-colors">
                    API Reference
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:text-white transition-colors">
                    Community
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:text-white transition-colors">
                    Guides
                  </a>
                </li>
              </ul>
            </div>

            {/* Support Section */}
            <div>
              <h3 className="text-lg font-semibold text-white mb-3">Support</h3>
              <ul className="space-y-1 text-sm">
                <li>
                  <a href="#" className="hover:text-white transition-colors">
                    Terms of Service
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:text-white transition-colors">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:text-white transition-colors">
                    Help Center
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:text-white transition-colors">
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          </div>

          {/* Social Media Section */}
          <div className="flex flex-col md:flex-row items-center justify-between border-t border-gray-700 pt-6 mt-8">
            {/* Social Media Links */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
