import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFont,
  faHandPointer,
  faPhotoVideo,
  faPaintBrush,
} from "@fortawesome/free-solid-svg-icons";

const ShowcaseSection = () => {
  return (
    <>
      {/* Title Section */}
      <section className="w-[100%]">
        <div className="container relative mb-40 mt-20">
          {" "}
          {/* Added -mt-6 to move up */}
          <h2 className="text-2xl font-medium text-gray-800 mb-4 text-center">
            Start Seeing Results Today
          </h2>
          <p className="text-md text-center text-gray-700 mb-8">
          Our mission is to make dynamic testing accessible and effortless for everyone.
          </p>
        </div>
      </section>

      {/* Showcase Section */}
      <section className="w-full pb-12 flex justify-center">
        {/* Parent Card */}
        <div className="relative w-full max-w-5xl border-4 border-purple-400 rounded-lg">
          {/* Image Card */}
          <div className="relative">
            <img
              src="https://www.shutterstock.com/image-photo/serious-indian-student-man-studying-260nw-2532428477.jpg" // Replace with your actual image URL
              alt="Background"
              className="w-full h-[300px] object-cover rounded-lg shadow-lg"
            />
          </div>

          {/* Overlay Cards */}
          <div className="absolute inset-0">
            {/* Top Section */}

            {/* Left Section */}
            <div className="absolute top-[-35%] left-[-20px] sm:left-[-20px] bg-white rounded-lg shadow-md p-4 w-[220px]">
              <h5 className="text-gray-800 text-md font-semibold mb-4 text-center">
                Select test target
              </h5>
              <div className="grid grid-cols-2 gap-2">
                <button className="flex flex-col items-center justify-center bg-gray-100 py-2 rounded shadow">
                  <FontAwesomeIcon
                    icon={faFont}
                    className="text-md text-gray-400 my-2"
                  />
                  <span className="text-xs text-gray-900">Text</span>
                </button>
                <button className="flex flex-col items-center justify-center bg-gray-100 py-2 rounded shadow">
                  <FontAwesomeIcon
                    icon={faHandPointer}
                    className="text-md text-gray-400 my-2"
                  />
                  <span className="text-xs text-gray-900">Buttons</span>
                </button>
                <button className="flex flex-col items-center justify-center bg-gray-100 py-2 rounded shadow">
                  <FontAwesomeIcon
                    icon={faPhotoVideo}
                    className="text-md text-gray-400 my-2"
                  />
                  <span className="text-xs text-gray-900">Images</span>
                </button>
                <button className="flex flex-col items-center justify-center bg-gray-100 py-2 rounded shadow">
                  <FontAwesomeIcon
                    icon={faPaintBrush}
                    className="text-md text-gray-400 my-2"
                  />
                  <span className="text-xs text-gray-900">Styles</span>
                </button>
              </div>
            </div>

            {/* Bottom Section */}
            <div className="absolute right-[-30%] bottom-[-40%] transform -translate-x-1/2 bg-white rounded-lg shadow-md p-4 w-[240px] ">
              <h5 className="text-md text-gray-800 font-semibold m-4 text-center">
                Test results
              </h5>
              {/* Variant A */}
              <div className="flex items-center justify-between mb-2">
                <span className="text-sm font-semibold text-gray-700">A</span>
                <div className="w-full bg-gray-100 h-4 rounded-lg overflow-hidden ml-2">
                  <div
                    className="bg-green-500 h-full"
                    style={{ width: "60%" }}
                  ></div>
                </div>
              </div>
              {/* Variant B */}
              <div className="flex items-center justify-between mb-2">
                <span className="text-sm font-semibold text-gray-700">B</span>
                <div className="w-full bg-gray-100 h-4 rounded-lg overflow-hidden ml-2">
                  <div
                    className="bg-yellow-500 h-full"
                    style={{ width: "45%" }}
                  ></div>
                </div>
              </div>
              {/* Variant C */}
              <div className="flex items-center justify-between mb-2">
                <span className="text-sm font-semibold text-gray-700">C</span>
                <div className="w-full bg-gray-100 h-4 rounded-lg overflow-hidden ml-2">
                  <div
                    className="bg-orange-500 h-full"
                    style={{ width: "30%" }}
                  ></div>
                </div>
              </div>
              {/* Variant D */}
              <div className="flex items-center justify-between mb-2">
                <span className="text-sm font-semibold text-gray-700">D</span>
                <div className="w-full bg-gray-100 h-4 rounded-lg overflow-hidden ml-2">
                  <div
                    className="bg-red-500 h-full"
                    style={{ width: "20%" }}
                  ></div>
                </div>
              </div>
              {/* Variant E */}
              <div className="flex items-center justify-between">
                <span className="text-sm font-semibold text-gray-700">E</span>
                <div className="w-full bg-gray-100 h-4 rounded-lg overflow-hidden ml-2">
                  <div
                    className="bg-purple-500 h-full"
                    style={{ width: "10%" }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ShowcaseSection;
