import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEdit,
  faSyncAlt,
  faCopy,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";

import {
  faShopify,
  faWordpress,
  faReact,
  faWix,
  faHtml5,
  faSalesforce,
  faMagento,
} from "@fortawesome/free-brands-svg-icons";

import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";

const OnboardingSection = () => {
  const [selectedElement, setSelectedElement] = useState("title");
  const [url, setUrl] = useState("");
  const [logoUrl, setLogoUrl] = useState(null);
  const [error, setError] = useState(null);
  const [variations, setVariations] = useState([
    { id: 1, name: "Red Apple" },
    { id: 2, name: "Juicy Apple" },
  ]);

  // Function to handle generating a new variation
  const handleGenerateVariation = (buttonRef) => {
    // Check if a new variation has already been generated
    if (variations.some((variation) => variation.name === "Delicious Apple")) {
      return; // Exit the function if the variation already exists
    }

    const newVariationId = variations.length + 1;
    setVariations([
      ...variations,
      { id: newVariationId, name: `Delicious Apple` },
    ]);
    // Hide the button after it is clicked
    if (buttonRef.current) {
      buttonRef.current.style.display = "none";
    }
  };

  // Function to handle selection of individual elements within the visual
  const handleSelect = (element) => {
    setSelectedElement(element);
  };

  const fetchLogo = async () => {
    try {
      const response = await fetch(`https://logodog.dev/api/get?url=${url}`, {
        headers: {
          Authorization:
            "Bearer lAKZkEGo3LLBv5wFsCrnsyGP61qawqbyw53F4cjn7cf8c9d7",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      if (data.success) {
        console.log("Logo URL:", data.logo_url); // Log the image URL to the console
        setLogoUrl(data.logo_url);
      } else {
        setError("Failed to fetch logo.");
      }
    } catch (error) {
      setError("Error fetching logo.");
      console.error("Fetch error:", error); // Log any errors that occur during fetch
    }
  };

  // Handle input change
  const handleUrlChange = (e) => {
    setUrl(e.target.value);
    setLogoUrl(null); // Reset logoUrl when URL changes
    setError(null); // Reset error when URL changes
  };

  // Handle fetching logo on button click
  const handleFetchLogo = () => {
    if (url) fetchLogo();
  };

  // Step data array
  const steps = [
    {
      number: "1",
      title: "Sign up",
      description: "Sign up and provide your website",
      visual: (
        <div className="w-full h-auto bg-white rounded-lg py-4 text-gray-500 relative flex flex-col gap-4">
          {/* Input Field with Arrow Button */}
          <div className="flex items-center bg-gray-100 text-gray shadow-md rounded-md p-1">
            <input
              type="text"
              value={url}
              onChange={handleUrlChange}
              placeholder="https://example.com"
              className="flex-grow bg-transparent text-sm text-black p-2 focus:outline-none"
            />
            <button
              onClick={handleFetchLogo}
              className="ml-2 px-3 py-1 text-gray-300 text-sm hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-100"
            >
              <FontAwesomeIcon icon={faArrowCircleRight} className="h-5 w-5" />
            </button>
          </div>
        </div>
      ),
    },

    {
      number: "2",
      title: "Select test target",
      description: "You can select any item on your page to run tests on.",
      visual: (
        <div className="w-full bg-gray-50 rounded-lg shadow-md p-4 mt-10 text-black mb-4 flex items-center gap-4 transition-transform duration-300">
          {/* Product Image */}
          <div
            onClick={() => handleSelect("image")}
            className="relative text-5xl text-center cursor-pointer"
          >
            {selectedElement === "image" && (
              <span className="absolute top-[-16px] left-1/2 transform -translate-x-1/2 text-xs font-semibold text-green-300 bg-green-800 p-1 rounded-md">
                Selected
              </span>
            )}
            <div
              className={`rounded-md ${
                selectedElement === "image"
                  ? "outline outline-2 outline-green-500"
                  : ""
              }`}
            >
              🍎
            </div>
          </div>

          {/* Product Details */}
          <div className="flex-1">
            <div className="relative inline-block cursor-pointer">
              {selectedElement === "title" && (
                <span className="absolute top-[-16px] left-1/2 transform -translate-x-1/2 text-xs font-semibold text-green-300 bg-green-800 p-1 rounded-md">
                  Selected
                </span>
              )}
              <span
                onClick={() => handleSelect("title")}
                className={`text-md text-black mb-4 font-semibold rounded-md ${
                  selectedElement === "title"
                    ? "outline outline-2 outline-green-500"
                    : ""
                }`}
              >
                Fresh Apple
              </span>
            </div>
            <p
              onClick={() => handleSelect("description")}
              className="relative text-gray-700 text-sm mt-2 cursor-pointer"
            >
              {selectedElement === "description" && (
                <span className="absolute top-[-10px] left-1/2 transform -translate-x-1/2 text-xs font-semibold text-green-300 bg-green-800 p-2 rounded-md">
                  Selected
                </span>
              )}
              <div
                className={`rounded-md ${
                  selectedElement === "description"
                    ? "outline outline-2 outline-green-500"
                    : ""
                }`}
              >
                Enjoy the crisp and refreshing taste of our hand-picked apples.
                Perfect for a healthy snack!
              </div>
            </p>
            {/* Price and Add to Cart Button in the same row */}
            <div className="flex items-center justify-between mt-2">
              <div
                onClick={() => handleSelect("price")}
                className="relative text-md text-black font-semibold cursor-pointer"
              >
                {selectedElement === "price" && (
                  <span className="absolute top-[-16px] left-1/2 transform -translate-x-1/2 text-xs font-semibold text-green-300 bg-green-800 p-1 rounded-md">
                    Selected
                  </span>
                )}
                <div
                  className={`rounded-md text-md ${
                    selectedElement === "price"
                      ? "outline outline-2 outline-green-500"
                      : ""
                  }`}
                >
                  $2.99
                </div>
              </div>
              <button
                onClick={(e) => {
                  e.stopPropagation(); // Prevent click propagation to the entire card
                  handleSelect("addToCart"); // Select the button
                }}
                className={`relative flex items-center text-sm text-black bg-gray-200 px-3 py-2 rounded-md ${
                  selectedElement === "addToCart"
                    ? "outline outline-2 outline-green-500"
                    : ""
                }`}
              >
                {selectedElement === "addToCart" && (
                  <span className="absolute top-[-16px] left-1/2 transform -translate-x-1/2 text-xs font-semibold text-green-300 bg-green-800 p-1 rounded-md">
                    Selected
                  </span>
                )}
                Add to cart
              </button>
            </div>
          </div>
        </div>
      ),
    },
    {
      number: "3",
      title: "Create variations",
      description:
        "Explore and refine different variations. Our AI will help you generate good variant ideas.",
      visual: (
        <div className="w-full h-auto bg-white rounded-lg py-4 text-black relative flex flex-col items-start gap-3">
          {/* First Variation - Default ("Fresh Apple") */}
          <div className="w-full bg-gray-50 p-3 rounded-lg shadow-md border-2 border-green-500 transition-all cursor-pointer flex items-center gap-3">
            <div className="w-8 h-8 flex items-center justify-center rounded-md bg-green-500 text-white font-bold">
              A
            </div>
            <span className="text-md font-medium">Fresh Apple</span>
          </div>

          {/* Variation Options */}
          <div className="w-full space-y-3">
            {variations.map((variation, index) => {
              // Define unique background colors for the labels
              const bgColors = [
                "bg-blue-500",
                "bg-purple-500",
                "bg-orange-500",
                "bg-teal-500",
              ];
              const bgColor = bgColors[index % bgColors.length]; // Cycle through colors if variations exceed 4

              return (
                <div
                  key={variation.id}
                  className="flex items-center justify-between bg-gray-50 p-6 mb-20 rounded-lg shadow-sm border-2 border-transparent hover:border-blue-500 transition-all cursor-pointer"
                >
                  <div className="flex items-center gap-3">
                    {/* Letter Label */}
                    <div
                      className={`w-8 h-8 flex items-center justify-center rounded-md ${bgColor} text-white font-bold`}
                    >
                      {String.fromCharCode(65 + index)}{" "}
                      {/* Convert index to A, B, C, etc. */}
                    </div>
                    <span className="text-md font-medium">
                      {variation.name}
                    </span>
                  </div>
                  <div className="flex gap-3">
                    <FontAwesomeIcon
                      icon={faSyncAlt}
                      className="text-blue-400 hover:text-blue-500 cursor-pointer"
                    />
                    <FontAwesomeIcon
                      icon={faEdit}
                      className="text-yellow-400 hover:text-yellow-500 cursor-pointer"
                    />
                    <FontAwesomeIcon
                      icon={faTrash}
                      className="text-red-400 hover:text-red-500 cursor-pointer"
                    />
                  </div>
                </div>
              );
            })}
          </div>

          {/* Generate and Confirm Buttons */}
          <div className="w-full flex justify-center items-center mt-4 relative">
            <button
              onClick={handleGenerateVariation}
              className="w-10 h-10 flex items-center justify-center rounded-full border-2 border-blue-500 text-blue-500 hover:border-blue-500 focus:outline-none"
            >
              <FontAwesomeIcon icon={faPlus} />
            </button>
            <button className="absolute right-0 px-4 py-2 bg-green-600 text-white text-sm font-medium rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500">
              Confirm
            </button>
          </div>
        </div>
      ),
    },
    {
      number: "4",
      title: "Effortless integration",
      description:
        "We integrate seamlessly to your website with just a single tag. We're happy to assist you in setting it up.",
      visual: (
        <div className="w-full h-auto bg-white rounded-lg text-gray-500 relative flex flex-col gap-4">
          {/* Logos Section */}
          <div className="flex flex-wrap items-center justify-center gap-6 my-4 mb-6 text-gray-600">
            <FontAwesomeIcon
              icon={faShopify}
              className="text-3xl"
              title="Shopify"
            />
            <FontAwesomeIcon
              icon={faWordpress}
              className="text-3xl"
              title="WordPress"
            />
            <FontAwesomeIcon
              icon={faReact}
              className="text-3xl text-blue-500"
              title="React"
            />
            <FontAwesomeIcon
              icon={faWix}
              className="text-3xl text-purple-500"
              title="Wix"
            />
            <FontAwesomeIcon
              icon={faHtml5}
              className="text-3xl text-orange-500"
              title="HTML5"
            />
            <FontAwesomeIcon
              icon={faSalesforce}
              className="text-3xl text-blue-700"
              title="Salesforce"
            />
            <FontAwesomeIcon
              icon={faMagento}
              className="text-3xl text-orange-600"
              title="Magento"
            />
          </div>

          {/* Script Input Field with Copy Button */}
          <div className="flex bg-gray-100 text-gray rounded-md shadow-md p-2">
            <input
              type="text"
              value={`<script src="https://ctaform.com/ab-testing.js"></script>`}
              readOnly
              className="w-full bg-transparent text-sm text-gray-500 p-1 focus:outline-none"
            />
            <button
              className="ml-2 px-3 py-1 text-gray-500 text-xs bg-gray-50 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-100"
              onClick={() =>
                navigator.clipboard.writeText(
                  `<script src="https://ctaform.com/ab-testing.js"></script>`
                )
              }
            >
              <FontAwesomeIcon icon={faCopy} /> {/* FontAwesome Copy Icon */}
            </button>
          </div>
        </div>
      ),
    },
  ];

  return (
    <section className="flex justify-center p-4 pb-12 md:pb-24 lg:pb-32">
      <div className="w-full max-w-6xl">
        {/* Title */}
        <h2 className="text-2xl font-medium text-gray-800 mb-4 text-center">
          Get tests running in 15 minutes
        </h2>
        <p className="text-md text-gray-700 mb-8 text-center">Automatic testing has never been this easy</p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-20">
          {steps.map((step) => (
            <div
              key={step.number}
              className="rounded-lg border shadow-md bg-white relative p-6"
            >
              <h3 className="px-6 tracking-tight text-md md:text-lg font-semibold text-black mt-2">
               {step.number}. {step.title}
              </h3>
              <p className="px-6 text-gray-600 text-sm mt-4 mb-6">
                {step.description}
              </p>
              <div className="px-6">{step.visual}</div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OnboardingSection;
