import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";

const ConversionCalculator = () => {
  const [baselineRate, setBaselineRate] = useState(3.5);
  const [rateIncrease, setRateIncrease] = useState(0.9);
  const [productPrice, setProductPrice] = useState(50);
  const [visitors, setVisitors] = useState(1000);
  const [conversions, setConversions] = useState(35);
  const [baselineRevenue, setBaselineRevenue] = useState(0);
  const [increasedRevenue, setIncreasedRevenue] = useState(0);
  const [revenueIncrease, setRevenueIncrease] = useState(0);
  const [manualBaselineRate, setManualBaselineRate] = useState(false);

  const calculateConversions = (rate, visitors) => Math.round((rate / 100) * visitors);
  const calculateRevenue = (conversions, price) => (conversions * price).toFixed(2);

  const handleIncrement = (setter, value, step) => {
    if (value + step >= 0) setter(value + step);
  };

  const handleDecrement = (setter, value, step) => {
    if (value - step >= 0) setter(value - step);
  };

  const recalculateValues = () => {
    const effectiveBaselineRate = manualBaselineRate
      ? baselineRate
      : visitors > 0
      ? parseFloat(((conversions / visitors) * 100).toFixed(2))
      : 0;

    if (!manualBaselineRate) setBaselineRate(effectiveBaselineRate);

    const baselineConversions = calculateConversions(effectiveBaselineRate, visitors);
    const baselineRevenue = calculateRevenue(baselineConversions, productPrice);

    const increasedRate = effectiveBaselineRate + rateIncrease;
    const increasedConversions = calculateConversions(increasedRate, visitors);
    const increasedRevenue = calculateRevenue(increasedConversions, productPrice);

    setBaselineRevenue(parseFloat(baselineRevenue));
    setIncreasedRevenue(parseFloat(increasedRevenue));
    setRevenueIncrease(parseFloat(increasedRevenue - baselineRevenue));
  };

  useEffect(() => {
    recalculateValues();
  }, [baselineRate, rateIncrease, productPrice, visitors, conversions, manualBaselineRate]);

  const handleConversionsChange = (newConversions) => {
    setManualBaselineRate(false);
    setConversions(newConversions);

    // Dynamically update the baseline rate based on new conversions and visitors
    const newBaselineRate = visitors > 0 ? parseFloat(((newConversions / visitors) * 100).toFixed(2)) : 0;
    setBaselineRate(newBaselineRate);
  };

  const renderInputControl = (label, value, setter, step, type = "number", customHandler = null) => (
    <div className="flex flex-col items-center">
      <label className="text-md font-medium text-gray-700 mb-2 block">{label}</label>
      <div className="flex items-center">
        <button
          onClick={() => handleDecrement(setter, value, step)}
          className="px-2 py-1 text-xl font-semibold text-gray-700 bg-gray-200 rounded-l-md"
        >
          <FontAwesomeIcon icon={faMinus} />
        </button>
        <input
          type={type}
          value={value}
          onChange={(e) =>
            customHandler
              ? customHandler(parseInt(e.target.value) || 0)
              : setter(parseFloat(e.target.value) || 0)
          }
          className="w-full text-md text-center font-semibold py-2 bg-gray-50 text-gray-700 rounded-none py-1 px-3 text-center focus:ring-2 focus:ring-blue-500"
        />
        <button
          onClick={() => handleIncrement(setter, value, step)}
          className="px-2 py-1 text-xl font-semibold text-gray-700 bg-gray-200 rounded-r-md"
        >
          <FontAwesomeIcon icon={faPlus} />
        </button>
      </div>
    </div>
  );

  return (
    <section className="flex flex-col items-center justify-center py-24 px-4 md:py-16 lg:py-24 bg-purple-100">
      <h2 className="text-2xl font-medium text-gray-800 mb-4 text-center">Revenue Growth Calculator</h2>
      <p className="text-md text-center text-gray-700 mb-8">
        Small adjustments in text and visuals can create significant impact.
      </p>

      <div className="w-full max-w-3xl p-6 rounded-xl bg-white border border-gray-200 shadow-lg">
        <div className="mb-8 text-center">
          <p className="text-xl font-medium text-gray-800">
            Make additional{" "}
            <motion.span
              animate={{ number: increasedRevenue }}
              transition={{ type: "spring", stiffness: 100, duration: 2 }}
              className="text-green-600 font-semibold"
            >
              ${increasedRevenue.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </motion.span>{" "}
            with <span className="text-black font-medium">{rateIncrease}%</span> improvement.
          </p>
          <p className="text-sm text-gray-600 mt-3">
            Not only revenue — get valuable insights into your audience's preferences.
          </p>
        </div>

        <div className="mb-6">
          <label className="text-md font-medium text-gray-700 block mb-2">
            Increased conversion rate: {rateIncrease}%
          </label>
          <input
            type="range"
            min="0.1"
            max="6"
            step="0.1"
            value={rateIncrease}
            onChange={(e) => setRateIncrease(parseFloat(e.target.value))}
            className="w-full"
          />
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-4 mb-6">
          {renderInputControl("Visitors", visitors, setVisitors, 1000)}
          {renderInputControl("Conversions", conversions, setConversions, 5, "number", handleConversionsChange)}
          {renderInputControl("Price", productPrice, setProductPrice, 1)}
          {renderInputControl("Conversion %", baselineRate, setBaselineRate, 0.1)}
        </div>
      </div>
    </section>
  );
};

export default ConversionCalculator;
