import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faRocket, faChartLine, faFlask } from '@fortawesome/free-solid-svg-icons';

const SubscriptionPlans = () => {
  // Plan data array
  const plans = [
    {
      name: "Starter",
      description: "Get started with simple tests for free",
      price: "0€",
      priceDetails: "/month",
      features: [
        { icon: faFlask, text: "One test at a time" },
        { icon: faCheck, text: "5 variations per test" },
        { icon: faChartLine, text: "10k requests /month" },
      ],
      buttonText: "Get Started",
      buttonLink: "#",
      iconColor: "text-green-500",
    },
    {
      name: "Growth",
      description: "Advanced testing capabilities for growing teams",
      price: "59€",
      originalPrice: "99€",
      priceDetails: "/month",
      discountNote: "40% OFF for the first 6 months!",
      features: [
        { icon: faFlask, text: "Up to 3 simultaneous tests" },
        { icon: faCheck, text: "20 variations per test" },
        { icon: faChartLine, text: "100,000 requests /month" },
      ],
      buttonText: "Sign Up",
      buttonLink: "#",
      iconColor: "text-blue-500",
      isPopular: true,
    },
  ];

  return (
    <section className="flex flex-col items-center justify-center py-12 px-2 md:py-24 lg:py-32">
      {/* Title */}
      <h2 className="text-3xl font-bold text-white mb-4 text-center">
        Choose Your Plan
      </h2>

      <p className="text-gray-200 text-center max-w-2xl mb-8 mx-4">
        Select the plan that best fits your needs.
      </p>
      
      <div className="w-full max-w-3xl p-6 rounded-xl bg-white border border-zinc-200 flex flex-col sm:flex-row gap-4 sm:gap-2">
        {plans.map((plan, index) => (
          <div key={index} className="w-full flex flex-row sm:flex-col justify-between gap-8 items-start sm:items-stretch">
            {/* Plan Details */}
            <div>
              <h2 className="font-semibold text-xl text-gray-800">{plan.name}</h2>
              <p className="text-sm text-gray-500 mt-1">{plan.description}</p>
              <ul className="mt-2 text-sm space-y-2">
                {plan.features.map((feature, featureIndex) => (
                  <li key={featureIndex} className="flex items-center gap-1 opacity-75">
                    <FontAwesomeIcon icon={feature.icon} className="mr-1 shrink-0 w-3 h-3 text-gray-400" />
                    <span>{feature.text}</span>
                  </li>
                ))}
              </ul>
            </div>
            
            {/* Price and Button */}
            <div className="space-y-4 flex flex-col items-end sm:items-center">
              <div className="flex items-baseline gap-1">
                <span className="font-semibold text-lg font-semibold text-gray-800">{plan.price}</span>
                <span className="opacity-50 text-sm">{plan.priceDetails}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );

};

export default SubscriptionPlans;